import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { TrackEventInput } from '@updater/ui-tracker';
import styled from '@emotion/styled';
import {
  H2,
  TextLink,
  Flex,
  Button,
  Alert,
  UpdaterHeader,
} from '@updater/ui-uds';
import { Form, FormProvider, SuperSchema } from '@updater/ui-informant';
import { useAuth } from 'context';
import { useLoginStore } from 'flows/core/store';
import { useWindowDimensions } from 'flows/core/hooks/use-window-dimensions';
import Link from 'next/link';
import { EMAIL_REGX } from 'constants/regx';
import { useTracking } from 'react-tracking';
import { useTrackViewOnMount } from 'utils/use-track-on-mount';

const schema: SuperSchema = {
  type: 'object',
  required: ['email', 'password'],
  properties: {
    email: {
      title: 'email',
      type: 'string',
      pattern: EMAIL_REGX,
      ui: {
        label: 'Email',
        inputType: 'email',
        messages: {
          required: 'Please enter your email address',
          pattern: 'Please enter a valid email address',
        },
      },
    },
    password: {
      title: 'password',
      type: 'string',
      minLength: 6,
      ui: {
        label: 'Password',
        component: 'security',
        messages: {
          minLength: 'Password must be at least 6 characters',
          required: 'Please enter a password',
        },
      },
    },
  },
};

type ContainerTypes = {
  deviceHeight: number;
  isSmallDevice: boolean;
};

// Tested on Iphone SE first generation (IOS 13.3.1)
// min-height was not working on the mentioned version of the mobile safari
// went for checking device height and define min-height accordingly
// setting min-height to 100% works as expected on the mobile device (Iphone SE (first) IOS 13.3)
const getContainerHeight = ({
  deviceHeight,
  isSmallDevice,
}: ContainerTypes): string => {
  if (isSmallDevice) {
    return '100%';
  }
  return `${deviceHeight}px`;
};

const Container = styled(Flex)<ContainerTypes>`
  min-height: ${({ deviceHeight, isSmallDevice }) =>
    getContainerHeight({ deviceHeight, isSmallDevice })};
  width: 100vw;
  overflow: scroll;
  overflow-x: hidden;
`;

interface FormData {
  email: string;
  password: string;
}

interface Props {
  title?: string;
}
interface CustomSubmitButtonProps {
  message: string;
  onClick?: () => void;
}

const CustomSubmitButton = ({ message, onClick }: CustomSubmitButtonProps) => {
  return (
    <Button type="submit" size="l" isFluidWidth onClick={onClick}>
      {message}
    </Button>
  );
};
const StyledTextLink = styled(TextLink)`
  color: ${({ theme }) => theme.colors.cta};
`;
export const AuthOverlay = ({ title }: Props) => {
  const { login, error } = useAuth();
  const { email } = useLoginStore();
  const router = useRouter();
  const { height, isSmallDevice } = useWindowDimensions();

  const { Track, trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'sign_in',
    object: 'overlay',
  });

  const handleSubmit = async (values: FormData) => {
    trackEvent({
      verb: 'submitted',
    });
    await login(values.email, values.password);
  };

  useTrackViewOnMount(trackEvent);

  useEffect(() => {
    if (error) {
      trackEvent({
        verb: 'errored',
        details: {
          error,
        },
      });
    }
  }, [trackEvent, error]);

  return (
    <Track>
      <Container
        flexDirection="column"
        deviceHeight={height}
        isSmallDevice={isSmallDevice}
      >
        <UpdaterHeader
          onLogoClick={() => router.push('/')}
          menuRequired={false}
        />
        <Flex
          flexDirection="column"
          alignItems="center"
          mt={['xxxl']}
          flexGrow="1"
          marginLeft={['s', 's', 's', 's']}
          marginRight={['s', 's', 's', 's']}
        >
          {router?.query?.info && !error && (
            <Alert mb="s" message={router.query.info} variant="warning" />
          )}
          <Flex
            flexDirection="column"
            maxWidth="490px"
            mt={['s', 's', 'xxxl', 'xxxl']}
          >
            <H2 as="h2" variant={['xxl', 'xxxl', 'xxxl', 'xxxl']} mb="m">
              {title}
            </H2>
            {error ? (
              <Alert
                mb="s"
                message={
                  // error?.message ||
                  // TODO: add this error to GQL response
                  'Your email or password was incorrect. Please try again.'
                }
                variant="error"
              />
            ) : (
              ''
            )}
            <FormProvider
              schema={schema}
              initialValues={{
                email: email ?? '',
                password: '',
              }}
              onSubmit={handleSubmit}
              ctaMode="enabled"
              submitComponent={<CustomSubmitButton message="Log In" />}
            >
              <Form />
            </FormProvider>
            <StyledTextLink
              data-cy="forgot-password"
              showTextDecoration
              variant="m"
              mt="xl"
              ml="0.5px"
              onClick={() => router.push('/forgot-password')}
            >
              Forgot Password?
            </StyledTextLink>
          </Flex>

          {/* <Text variant="m" pt="m" pb="m">
          Don&apos;t have an account? {` `}
          <TextLink
            data-cy="sign-up"
            showTextDecoration
            variant="m"
            onClick={() => router.push('/sign-up')}
          >
            [Sign Up]
          </TextLink>
        </Text> */}
        </Flex>
      </Container>
    </Track>
  );
};
