import { gql } from '@apollo/client';

export const tvInternetOfferVerticalQuery = gql`
  query HomeServicesReservation($id: ID!) {
    homeServicesReservation(moveId: $id) {
      reservation {
        offer {
          provider {
            name
          }
          price
          term
          services {
            internet {
              kind
              speed {
                mbps
                tier
              }
            }
            tv {
              channels {
                count
              }
            }
          }
          introductoryPrice {
            price
          }
        }
      }
    }
  }
`;
